import { addPropertyControls, ControlType } from "framer"

/**
 * @framerSupportedLayoutWidth any
 * @framerSupportedLayoutHeight any
 */
export default function Rating(props) {
    const { mode } = props
    let { filledStar, emptyStar } = props

    const sizeString = `${props.size}px`

    if (mode == "default") {
        filledStar = (
            <div
                style={{
                    width: sizeString,
                    height: sizeString,
                    color: props.filledColor,
                }}
            >
                {starSvg(sizeString, props.filledColor)}
            </div>
        )

        emptyStar = (
            <div
                style={{
                    width: sizeString,
                    height: sizeString,
                    color: props.emptyColor,
                }}
            >
                {starSvg(sizeString, props.emptyColor)}
            </div>
        )
    }

    let stars: any[] = []

    let filledStarCount = Math.min(props.totalStars, Math.floor(props.value))

    for (let i = 0; i < filledStarCount; i++) {
        // Add filled star
        stars.push(filledStar)
    }

    if (stars.length < props.totalStars && props.value % 1 != 0) {
        // Add partially filled star
        const percent = (props.value % 1) * 100

        const maskFilled = `linear-gradient(to right, white 0 ${percent}%, transparent ${percent}% 100%)`
        const maskEmpty = `linear-gradient(to right, transparent 0 ${percent}%, white ${percent}% 100%)`

        stars.push(
            <div
                style={{
                    position: "relative",
                }}
            >
                <div
                    style={{
                        WebkitMaskImage: maskFilled,
                        maskImage: maskFilled,
                    }}
                >
                    {filledStar}
                </div>
                <div
                    style={{
                        position: "absolute",
                        inset: 0,
                        WebkitMaskImage: maskEmpty,
                        maskImage: maskEmpty,
                    }}
                >
                    {emptyStar}
                </div>
            </div>
        )
    }

    for (let i = stars.length; i < props.totalStars; i++) {
        // Add empty star
        stars.push(emptyStar)
    }

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                gap: props.gap,
            }}
        >
            {stars}
        </div>
    )
}

Rating.defaultProps = {
    filledStar: null,
    filledColor: "#FFB92E",
    emptyStar: null,
    emptyColor: "#EBEBEB",
    size: 40,
    gap: 10,
    value: 3.5,
    totalStars: 5,
}

addPropertyControls(Rating, {
    mode: {
        type: ControlType.Enum,
        defaultValue: "default",
        options: ["default", "custom"],
        optionTitles: ["Default", "Custom"],
        displaySegmentedControl: true,
    },
    filledStar: {
        type: ControlType.ComponentInstance,
        title: "Filled",
        hidden(props) {
            return props.mode !== "custom"
        },
    },
    emptyStar: {
        type: ControlType.ComponentInstance,
        title: "Empty",
        hidden(props) {
            return props.mode !== "custom"
        },
    },
    filledColor: {
        type: ControlType.Color,
        defaultValue: Rating.defaultProps.filledColor,
        title: "Filled",
        hidden(props) {
            return props.mode !== "default"
        },
    },
    emptyColor: {
        type: ControlType.Color,
        defaultValue: Rating.defaultProps.emptyColor,
        title: "Empty",
        hidden(props) {
            return props.mode !== "default"
        },
    },
    size: {
        type: ControlType.Number,
        defaultValue: Rating.defaultProps.size,
        min: 1,
        step: 1,
        hidden(props) {
            return props.mode !== "default"
        },
    },
    gap: {
        type: ControlType.Number,
        defaultValue: Rating.defaultProps.gap,
        min: 0,
    },
    value: {
        type: ControlType.Number,
        defaultValue: Rating.defaultProps.value,
        min: 0,
        max: 100,
        step: 0.1,
        displayStepper: true,
    },
    totalStars: {
        type: ControlType.Number,
        defaultValue: Rating.defaultProps.totalStars,
        min: 1,
        max: 100,
        step: 1,
        displayStepper: true,
    },
})

function starSvg(size, color) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 40 40"
            width={size}
            height={size}
        >
            <path
                d="M 18.097 2.289 C 18.693 0.441 21.307 0.441 21.903 2.289 L 25.136 12.312 C 25.403 13.14 26.174 13.7 27.044 13.698 L 37.575 13.675 C 39.517 13.671 40.325 16.158 38.751 17.296 L 30.218 23.467 C 29.513 23.977 29.219 24.884 29.489 25.71 L 32.765 35.719 C 33.37 37.565 31.254 39.101 29.686 37.957 L 21.179 31.748 C 20.477 31.235 19.523 31.235 18.821 31.748 L 10.314 37.957 C 8.746 39.101 6.63 37.565 7.235 35.719 L 10.511 25.71 C 10.781 24.884 10.487 23.977 9.782 23.467 L 1.249 17.296 C -0.325 16.158 0.483 13.671 2.425 13.675 L 12.956 13.698 C 13.826 13.7 14.597 13.14 14.864 12.312 Z"
                fill={color}
            ></path>
        </svg>
    )
}
